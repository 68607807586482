<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <div class="pe-2 ps-3 app-title text-h5">
            Mini Games
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="d-flex flex-column pa-5">
            <!-- <div class="d-flex justify-space-between">
              <div class="text-h6">
                {{ $t("string.chapter") }} : Hellllo
              </div>
              
            </div>
            <div>{{ $t("string.question") }} : Meow</div> -->
            <v-img
              :src="require('@/assets/miniGames/rock.webp')"
              width="200"
              class="mx-auto mt-9 mb-2"
            ></v-img>
            <!-- <div class="text-center">
              <div class="text-h5">
                {{ $t("string.high_score") }} :
                bruh
              </div>
            </div> -->

            <div class="d-flex justify-center ma-3 mx-auto">
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="100"
                  height="80"
                  :color="
                    difficulty == 1 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 1"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.easy") }}
                    </div>
                    <!-- <div>+50</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 0</div>
                  </span>
                </v-card>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="100"
                  height="80"
                  :color="
                    difficulty == 2 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 2"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.medium") }}
                    </div>
                    <!-- <div>+100</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 50</div>
                  </span>
                </v-card>
              </v-hover>
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 6 : 2"
                  class="ma-2 d-flex align-center justify-center"
                  width="100"
                  height="80"
                  :color="
                    difficulty == 3 ? 'yellow lighten-4' : 'grey lighten-4'
                  "
                  :class="{ 'on-hover': hover }"
                  @click="difficulty = 3"
                >
                  <span>
                    <div class="text-cption font-weight-medium">
                      {{ $t("string.hard") }}
                    </div>
                    <!-- <div>+150</div> -->
                    <div class="text-h4 text-center text--primary mb-3 pt-3">
                      <v-row align="center" justify="center">
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                        <v-img
                          max-width="15"
                          max-height="25"
                          class="mt-2"
                          :src="
                            require('@/assets/bugCatcher/DifficultyFire.png')
                          "
                        ></v-img>
                      </v-row>
                    </div>
                    <div class="text-center text-caption">+ 100</div>
                  </span>
                </v-card>
              </v-hover>
            </div>
            <v-btn class="text-h6" block large @click="play()" id="StartGame">
              {{ $t("action.start") }}
            </v-btn>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    settings: (state) => state.settings.data,
    miniGame: (state) => state.miniGame.data,
  }),
  props: [
    //
  ],
  data: () => ({
    schoolYear: null,
    difficulty: 1,
  }),
  created() {
  },
  mounted() {
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    }else {
      this.schoolYear = this.auth.User.schoolYear;
    }
  },
  methods: {
    play() {
      let tempMiniGame;
      tempMiniGame = this.$_.cloneDeep(this.miniGame);
      tempMiniGame.currnet = {
        gameid: this.$route.params.game,
        difficulty: this.difficulty,
        score: 0,
        round: {},
      };
      this.$store.commit("updateMiniGame", tempMiniGame);
      this.$router.push({
        name: "PageMiniGamesPlay",
        query: { difficulty: this.difficulty },
      });
    },
    exit() {
      this.$router.push({
        name: "PageExploreMiniGames",
      });
    },
  },
};
</script>

    <style scoped>
.list-panel {
  margin-top: 20px;
  padding: 5px 0;
}

.list-card {
  padding: 0 10px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ticket-card {
  background-color: #fff;
  height: fit-content;
  padding: 0 10px;
}

.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0 !important;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.no-boss {
  filter: brightness(0);
  opacity: 50%;
}
</style>